import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../css/global.css";
import "../css/about.css";

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <section className="about">
        <h3>Background</h3>
        <p>
          After stints in New York, London and Melbourne, Emma Williams now
          lives and works in Hertfordshire, UK. She began studying towards a
          career in Fine Art straight out of school, via the Chelsea College of
          Arts. However her interests in other forms of creativity spurred her
          to delay an art trajectory, and experiment with graphic design and
          animation. These interests inevitably led to a career in digital
          design and software development. Throughout her life she continued to
          return to fine art regularly, firstly being heavily involved in the
          art scene during her years in Melbourne, showing at a solo exhibition
          and multiple group shows, and then, on subsequent return to London,
          running a business selling prints created while exploring and
          experimenting with screen printing techniques. After a move to
          Hertfordshire, she now works primarily with paint, ink and charcoal,
          creating abstract works from loose and impulsive marks, restrained by
          predefined visual rules.
        </p>
      </section>
    </Layout>
  );
};

export default AboutPage;
